import { createWebHistory, createRouter } from "vue-router"

export const routes = {
  "home": {
    path: "/",
    name: "home",
    component: () => import("./components/Welcome.vue"),
  },
  "admin-home":{
    path: "/admin",
    name: "admin-home",
    component: () => import("./components/admin/AdminHome.vue"),
  },
  "admin-collection-list": {
    path: "/admin/collection/list",
    name: "admin-collection-list",
    component: () => import("./components/admin/CollectionList.vue"),
  },
  "admin-collection-create": {
    path: "/admin/collection/create",
    name: "admin-collection-create",
    component: () => import("./components/admin/CollectionForm.vue"),
    props: () => ({ create: true }),
  },
  "admin-collection-update": {
    path: "/admin/collection/update/:id_collection",
    name: "admin-collection-update",
    component: () => import("./components/admin/Collection.vue"),
    props: route => ({ id_collection: String(route.params.id_collection) }),
  },
  "admin-collection-export": {
    path: "/admin/collection/export/:id_collection",
    name: "admin-collection-export",
    component: () => import("./components/admin/Exporter.vue"),
    props: route => ({ id_collection: String(route.params.id_collection) }),
  },
  "admin-export-list": {
    path: "/admin/export/list",
    name: "admin-export-list",
    component: () => import("./components/admin/ExportList.vue"),
    props: route => ({ id_collection: String(route.params.id_collection) }),
  },
  "admin-thing-list": {
    path: "/admin/thing/list",
    name: "admin-thing-list",
    component: () => import("./components/admin/ThingList.vue"),
    props: route => ({ id_collection: String(route.params.id_collection), create: true }),
  },
  "admin-thing-create": {
    path: "/admin/thing/create/:id_collection",
    name: "admin-thing-create",
    component: () => import("./components/admin/ThingForm.vue"),
    props: route => ({ id_collection: String(route.params.id_collection), create: true }),
  },
  "admin-thing-update": {
    path: "/admin/thing/update/:id",
    name: "admin-thing-update",
    component: () => import("./components/admin/ThingForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-attribute-create": {
    path: "/admin/attribute/create/:id_collection",
    name: "admin-attribute-create",
    component: () => import("./components/admin/AttributeForm.vue"),
    props: route => ({ id_collection: String(route.params.id_collection), create: true }),
  },
  "admin-attribute-update": {
    path: "/admin/attribute/update/:id",
    name: "admin-attribute-update",
    component: () => import("./components/admin/AttributeForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-attribute_group-list": {
    path: "/admin/attribute_group/list/:id_collection",
    name: "admin-attribute_group-list",
    component: () => import("./components/admin/AttributeGroupList.vue"),
    props: route => ({ id_collection: String(route.params.id_collection) }),
  },
  "admin-attribute_group-create": {
    path: "/admin/attribute_group/create/:id_collection",
    name: "admin-attribute_group-create",
    component: () => import("./components/admin/AttributeGroupForm.vue"),
    props: route => ({ id_collection: String(route.params.id_collection), create: true }),
  },
  "admin-attribute_group-update": {
    path: "/admin/attribute_group/update/:id",
    name: "admin-attribute_group-update",
    component: () => import("./components/admin/AttributeGroupForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-attribute-select-update": {
    path: "/admin/attribute_select/update/:id",
    name: "admin-attribute-select-update",
    component: () => import("./components/admin/AttributeSelectForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-crawler-list": {
    path: "/admin/crawler/list",
    name: "admin-crawler-list",
    component: () => import("./components/admin/CrawlerList.vue"),
  },
  "admin-crawler-update": {
    path: "/admin/crawler/update/:id",
    name: "admin-crawler-update",
    component: () => import("./components/admin/CrawlerForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-crawler-run-update": {
    path: "/admin/crawler_run/update/:id",
    name: "admin-crawler-run-update",
    component: () => import("./components/admin/CrawlerRunForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-crawler-create": {
    path: "/admin/crawler/create",
    name: "admin-crawler-create",
    component: () => import("./components/admin/CrawlerForm.vue"),
    props: () => ({ create: true }),
  },
  "admin-scripts": {
    path: "/admin/scripts",
    name: "admin-scripts",
    component: () => import("./components/admin/Scripts.vue"),
  },
  "admin-bulk-action-list": {
    path: "/admin/bulk-action/list",
    name: "admin-bulk-action-list",
    component: () => import("./components/admin/BulkActionList.vue"),
  },
  "admin-bulk-actions-update": {
    path: "/admin/bulk-actions/update/:id",
    name: "admin-bulk-actions-update",
    component: () => import("./components/admin/BulkActionForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-bulk-action-create": {
    path: "/admin/bulk-action/create",
    name: "admin-bulk-action-create",
    component: () => import("./components/admin/BulkActionForm.vue"),
    props: () => ({ create: true }),
  },
  "admin-dynamic-image-list": {
    path: "/admin/dynamic-image/list",
    name: "admin-dynamic-image-list",
    component: () => import("./components/admin/dynamic-image/DynamicImageList.vue"),
  },
  "admin-dynamic-image-create": {
    path: "/admin/dynamic-image/create",
    name: "admin-dynamic-image-create",
    component: () => import("./components/admin/dynamic-image/DynamicImageForm.vue"),
    props: () => ({ create: true }),
  },
  "admin-dynamic-image-update": {
    path: "/admin/dynamic-image/update/:id",
    name: "admin-dynamic-image-update",
    component: () => import("./components/admin/dynamic-image/DynamicImageForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-dynamic-image-data-source-list": {
    path: "/admin/dynamic-image-data-source/list",
    name: "admin-dynamic-image-data-source-list",
    component: () => import("./components/admin/dynamic-image/DynamicImageDataSourceList.vue"),
  },
  "admin-dynamic-image-data-source-create": {
    path: "/admin/dynamic-image-data-source/create",
    name: "admin-dynamic-image-data-source-create",
    component: () => import("./components/admin/dynamic-image/DynamicImageDataSourceForm.vue"),
    props: () => ({ id: null, create: true }),
  },
  "admin-dynamic-image-data-source-update": {
    path: "/admin/dynamic-image-data-source/update/:id",
    name: "admin-dynamic-image-data-source-update",
    component: () => import("./components/admin/dynamic-image/DynamicImageDataSourceForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-user-list": {
    path: "/admin/user/list",
    name: "admin-user-list",
    component: () => import("./components/admin/UserList.vue"),
  },
  "admin-mapper-create": {
    path: "/admin/mapper/create",
    name: "admin-mapper-create",
    component: () => import("./components/admin/MapperForm.vue"),
    props: () => ({ create: true }),
  },
  "admin-mapper-update": {
    path: "/admin/mapper/update/:id",
    name: "admin-mapper-update",
    component: () => import("./components/admin/MapperForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-mapper-list": {
    path: "/admin/mapper/list",
    name: "admin-mapper-list",
    component: () => import("./components/admin/MapperList.vue")
  },
  "admin-changeset-list": {
    path: "/admin/changeset/list",
    name: "admin-changeset-list",
    component: () => import("./components/admin/ChangesetList.vue")
  },
  "admin-changeset-update": {
    path: "/admin/changeset/update/:id",
    name: "admin-changeset-update",
    component: () => import("./components/admin/ChangesetForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
}

const router = createRouter({
  history: createWebHistory(),
  routes: Object.values(routes),
})

export default router
